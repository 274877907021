footer {
}
.whatsapp-fixed {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    z-index: 999;
    width: 80px;
    @media screen and (max-width: 768px) {
        bottom: 0px;
        right: 0px;
        width: 75px;
    }
}

@keyframes pulsar {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.pulsar {
    animation: pulsar 1s ease-in-out infinite;
    animation-iteration-count: 1;
}

.oportunidades {
    padding: {
        top: 40px;
        bottom: 40px;
    }

    .titulo {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: $branco;
        text-transform: uppercase;
        font: {
            font-weight: 400;
            font-size: 30px;
        }
        margin-bottom: 50px;
    }

    .oportunidades-list {
        display: flex;
        li {
            flex: 1;
            display: flex;
            margin: 10px 30px;
            a {
                padding: 30px 55px;
            }
            @media screen and (max-width: 991px) {
                margin: 10px;
            }
        }

        @media screen and (max-width: 768px) {
            display: block;
            li {
                display: block;
            }
        }
    }

    .botao-default {
        width: 100%;
        display: inline-block;
        background: $branco;
        border-top: 3px solid $main-color;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        text-transform: uppercase;
        color: $main-color;
        text-align: center;
        border-radius: 10px 0px 10px 0px;
        font: {
            size: 15px;
            weight: 700;
        }
        @include transition(0.3s);
        /*height: 150px;*/
        line-height: 22px;
        i {
            display: block;
            font-size: 36px;
            margin-bottom: 10px;
        }
        &:hover {
            background: $main-color;
            color: $branco;
        }
        &.ligamos-para-voce {
            background-size: cover;
        }
        &.nao-encontrou {
            background-size: cover;
        }
        &.compramos {
            background-size: cover;
        }
        div.linha {
            padding-bottom: 15px;
            border-radius: 3px;
        }
    }
}

@media (max-width: 768px) {
    .oportunidades {
        .oportunidades-list {
            li {
                a {
                    padding: 10px 0px;
                }
            }
        }
        .botao-default {
            background-image: none !important;
            background-color: $branco;
            border-top: solid 3px $main-color;
            border-radius: 10px 0px 10px 0px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

            div.linha {
                border: none;
                padding-bottom: 0px;
                color: $main-color;
            }

            i {
                display: inline;
                font-size: 24px;
                margin: 0;
                margin-right: 10px;
                padding-top: 0px;
            }
        }
    }
}

.menu-footer-nav_items {
    font-size: 20px;
    color: #24292B;
}
.box-menu-footer {
    background: $main-color;
    padding: {
        top: 30px;
        bottom: 30px;
    }
    .menu-footer {
        text-align: center;
        li {
            display: inline;
            padding-left: 12px;
            padding-right: 12px;
            a {
                color: $branco;
                text-transform: uppercase;
                font: {
                    weight: 900;
                    size: 14px;
                }
                @include transition(0.3s);
                &:hover {
                    color: $preto;
                }
            }
        }
    }
}

.box-newsletter {
    background: $theme-color;
    padding: {
        top: 30px;
        bottom: 30px;
    }
    .newsletter-block {
        padding: 10px 50px;
    }
    .nome-coluna {
        padding-left: 60px;
        color: $branco;
        display: block;
        width: 100%;

        h3 {
            font: {
                weight: 700;
                size: 22px;
            }
        }
        p {
            font-size: 12px;
            margin: 5px 0px;
        }
        &.money-icon {
            background: transparent url("../img/icones/icon_money.png") no-repeat scroll left center;
        }
        &.cars-icon {
            background: transparent url("../img/icones/icon_carro.png") no-repeat scroll left center;
        }
    }

    .info-coluna {
        display: block;
        width: 100%;
        padding: 10px 60px;
        font: {
            weight: 700;
            size: 14px;
        }
        color: $branco;
    }
    .botao-default {
        display: inline-block;
        background: $secondary-color;
        text-transform: uppercase;
        color: $branco;
        text-align: center;
        padding: 6px 24px;
        @include transition(0.3s);
        @include radius(1px);
        &:hover {
            background: $main-color;
            border: 1px solid $secondary-color;
        }
    }

    .newsletter-form {
        display: flex;
        input {
            vertical-align: middle;
            display: flex;
            @include radius(0px);
            &[type="submit"] {
                width: 50px;
                background-color: $secondary-color;
                border: 0;
                color: $branco;
                font-size: 20px;
                line-height: 10px;
            }
            &[type="text"] {
            }
        }
    }
}

.footer-menu {
    span {
        font-family: "ZeekrText", sans-serif;
    }
}

.box-assinatura {
    background: #d7d0c8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: {
        top: 13px;
        bottom: 13px;
    }

    .todos-os-direitos {
        text-align: left;

        .copy {
            color: #363636;
            display: inline-block;
            padding-left: 10px;
            font: {
                weight: 300;
                size: 11px;
                style: italic;
            }
        }
    }

    .ass {
        // text-align: right;
    }
    span {
        font-family: "ZeekrHeadline", sans-serif;
    }
}

#map {
    width: 100%;
    height: 380px;
    background: $preto;
}

@media (max-width: 680px) {
    ul.lojas-mapa {
        li {
            //border-bottom: solid 2px #3f3f3f !important;
        }
    }
}
.container-flutuante-mapa-footer {
    position: absolute;
    top: -30px;
    right: 20px;
    background-color: $main-color;
    color: white;
    padding: 15px;
    border-radius: 20px;
    width: 400px;

    @media (max-width: 680px) {
        width: 100%;
        position: unset;
        margin-bottom: 10px;
    }
}

ul.lojas-mapa {
    display: flex;
    flex: auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    li {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
            display: block;
            padding: 10px 5px;
            font-size: 13px;
            color: $main-color;
            text-align: left;
            height: 100%;
            img {
                float: left;
                margin: 2px 10px;
                margin-top: -5px;
                width: 20px;
            }
            .black {
                display: block;
            }
            .white {
                display: none;
            }
            &.active,
            &:hover {
                background-color: transparent;
                color: #333;

                .black {
                    display: none;
                }
                .white {
                    display: block;
                }
            }
        }
        i {
            font-size: 42px;
            margin-right: 10px;
        }
    }
}

.business-section {
    background-color: #000;
    color: #fff;
    padding: 80px 0;
}

.business-section h2 {
    font-size: 48px; /* Ajustado para 48px */
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 0;
}

.business-section .icon {
    font-size: 60px;
    color: #e30613;
    margin-bottom: 10px;
    text-align: left;
}

.business-section p {
    color: #b3b3b3;
    font-size: 16px; /* Ajustado para 16px */
    line-height: 1.6;
}

.business-section .btn-custom {
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    font-size: 16px; /* Ajustado para 16px */
    font-weight: bold;
    padding: 12px 25px;
    text-transform: uppercase;
    margin-top: 20px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    border-radius: 0px;
}

.business-section .btn-custom:hover {
    background-color: #e30613;
    border-color: #e30613;
    color: #fff;
}

.business-section .col-lg-4 h2 {
    font-size: 48px; /* Título principal ajustado para 48px */
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
}

.business-section .col-lg-8 .row {
    display: flex;
    justify-content: space-between;
}

.business-section .col-lg-8 .row .col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 15px;
}

.business-section .col-lg-8 .row .col-md-4 h5 {
    font-size: 22px; /* Títulos internos ajustados para 22px */
    font-weight: 600;
    color: #fff;
}

.business-section .col-lg-8 .row .col-md-4 p {
    margin-bottom: 25px;
    font-size: 16px; /* Ajustado para 16px */
    flex-grow: 1;
}

.about__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.about__text {
    flex: 1;
}

.about__text h2 {
    font-size: 36px;
    font-weight: 700;
    color: #e30613;
    margin-bottom: 15px;
}

.about__text h3 {
    font-size: 22px;
    font-weight: 700;
    color: #e30613;
    margin-bottom: 20px;
}

.about__text p {
    font-size: 16px;
    color: #565656;
    line-height: 1.6;
    margin-bottom: 15px;
}

.about__text a {
    display: block;
    background-color: #e30613;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    transition: background-color 0.3s ease;
    max-width: 574px; /* Tamanho exato do botão no Figma */
    width: 100%;
    text-align: center;
    margin: 20px auto 0;
}

.about__text a:hover {
    background-color: #d0040f;
}

.about__video iframe {
    width: 100%;
    height: 315px;
    border-radius: 0;
}

.custom-section {
    background-color: #e30613;
    min-height: 50px;
}

.social-media i {
    color: #24292B;
    margin-right: 10px;
}

.js-showmap {
    height: 100%;

    .nome-loja {
    }
    .endereco {
        font-size: 1.15rem;
        font-weight: bold;
        display: inline-block;
    }
    .cidade,
    .cep {
        font-size: 1rem;
    }
}

.maps-full {
    position: relative;
    > .container {
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .form-component {
        .input-item {
            display: flex;
            flex-flow: column;

            margin-bottom: 15px;

            label {
                color: $dark-gray;
                font-size: 14px;
            }
            input,
            select {
                width: 100%;
                color: $dark-gray;
                font-size: 20px;
                background: transparent;

                border: none;
                border-bottom: 1px solid $dark-gray;

                &[type="checkbox"] {
                    width: auto;
                }

                &::placeholder {
                    color: $dark-gray;
                }
            }

            textarea {
                color: $dark-gray;
                height: 100px;
                background: transparent;
                resize: none;
                border: 1px solid $dark-gray;
            }
        }

        .radio-group {
            display: flex;
            justify-content: space-evenly;

            .input-radio {
                label {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    cursor: pointer;
                }

                input[type="radio"] {
                    width: 0;
                    position: absolute;
                    left: -1000%;

                    &:checked + label {
                        transform: scale(1.03);
                        span {
                            /*color: $color-active !important;*/
                            font-weight: bold;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .number {
            display: none;
        }
    }
}
.map-content {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    display: none;
    iframe {
        width: 100%;
        height: 350px;
    }
    &.active {
        display: block;
    }
}
/*------------------------------------*
    #full-map-section
/*------------------------------------*/
.footer-main {
    padding: 25px 0;
    color: #24292b;
}

.full-map-info {
    background-color: #e30613;
    padding: 25px 0;
    color: white;

    .copy {
        font-size: 0.8rem;
        font-weight: 300;
    }

    .social-medias-footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        li {
            margin-left: 10px;
        }
    }

    .logo-footer {
        img {
            max-width: -webkit-stretch;
            max-height: 70px;
        }
    }

    h3 {
        text-transform: uppercase;
    }

    .contact-menu {
        a:focus,
        a:visited {
            color: inherit;
        }

        .contact-menu__item {
            margin-bottom: 10px;
        }

        .contact-menu__link {
            strong {
                color: white;
            }

            :visited {
                color: inherit;
            }
        }
    }

    .icon-phone {
        display: inline-block;
        height: 22px;
        width: 22px;
        background: url("../img/icones/icon_phone_orange.png");
        margin-right: 5px;
        position: relative;
        top: 5px;
    }

    .icon-whatsapp {
        display: inline-block;
        height: 22px;
        width: 22px;
        background: url("../img/icones/icon_whatsapp_green.png");
        margin-right: 5px;
        position: relative;
        top: 5px;
    }
}
