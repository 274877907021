* {
    font-family: "ZeekrText", sans-serif;
}

body {
    background-color: #f1efeb;
}

.ratio-3x2 {
    --bs-aspect-ratio: 66%;
}

.main-color {
    color: $main-color;
}

.secondary-color {
    color: $secondary-color;
}

.main-bg {
    background-color: $main-color;
}

.container-titulo-pagina {
    background: #F1EFEB;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titulo-pagina {
    font: {
        size: 65px;
        weight: 500;
    }

    color: #24292B;
    text-align: center;
    margin-bottom: 10%;
}

.box-slider-desktop {
    position: relative;

    .seta-esquerda,
    .seta-direita,
    .seta-direita-mobile,
    .seta-esquerda-mobile {
        z-index: 100;
        position: absolute;
        top: 45%;
        display: none;
        width: 50px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        @include transition(0.3s);
        cursor: pointer;
        font-size: 50px;
    }

    &:hover {

        .seta-esquerda,
        .seta-direita,
        .seta-direita-mobile,
        .seta-esquerda-mobile {
            display: block;
        }
    }

    .seta-direita,
    .seta-direita-mobile {
        right: 20px;
    }

    .seta-esquerda,
    .seta-esquerda-mobile {
        left: 20px;
    }

    .seta-esquerda img,
    .seta-direita img {
        width: 100%;
    }
}

.box-principais {
    position: relative;

    .seta-esquerda,
    .seta-direita,
    .seta-direita-mobile,
    .seta-esquerda-mobile {
        z-index: 100;
        color: #333;
        position: absolute;
        top: 35%;
        display: none;
        width: 50px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        @include transition(0.3s);
        cursor: pointer;
        font-size: 50px;

        @media (min-width: 600px) {
            display: block;
        }
    }

    .seta-direita,
    .seta-direita-mobile {
        right: -35px;
    }

    .seta-esquerda,
    .seta-esquerda-mobile {
        left: -35px;
    }

    .card-veiculo {
        padding: 0px 20px;
    }
}

.slider-mobile {
    display: none;
}

.slider-desktop {
    visibility: hidden;
}

.slider-mobile {
    visibility: hidden;
}

.slider-desktop,
.slider-mobile {
    .item-slider {
        img {
            display: inline-block;
            max-width: 1920px;
            width: 100%;
        }
    }
}

@media (max-width: 700px) {
    .slider-desktop {
        display: none;
    }

    .slider-mobile {
        display: block;
    }
}

.box-busca-avancada {
    position: relative;

    label {
        font-size: 14px;

        &>input[type="radio"] {
            margin-left: 20px;
        }
    }

    .noUi-horizontal {
        padding: 0 10px;
        margin-top: 7px;
        height: 8px;
    }

    .noUi-horizontal .noUi-handle {
        height: 18px;

        &:before,
        &:after {
            height: 9px;
            top: 4px;
        }
    }

    .btn-mais-filtro {
        color: white;
        text-decoration: none;
    }
}

.titulo-marcas {
    display: block;
    text-transform: uppercase;
    background: #fff;
    margin: 0 auto;
    margin-top: -30px;
    width: 12%;

    font: {
        size: 12px;
    }

    color: #a1a1a1;
}

.marcas-seminovos {
    text-align: center;

    .item-marca-seminovos {
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        max-width: 80px;
        max-height: 50px;
        padding: 10px;
        display: inline-block;
        border: solid 1px #e5e5e5;
        @include radius(5px);
        @include transition(0.3s);

        img {
            display: inline-block;
        }

        &:hover {
            border: solid 1px #8a8a8a;
        }
    }
}

.marcas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .selected {
        border: solid 2px $main-color !important;
    }

    .item-marcas {
        margin: 10px;
        padding: 10px;
        border: solid 1px #e5e5e5;
        border-radius: 5px;
        font-size: 0.8rem;

        img {
            display: inline-block;
            object-fit: cover;
            transition: transform 0.5s ease-in-out;
        }

        &:hover {
            border: solid 1px #8a8a8a;
        }

        &:hover img {
            transform: scale(1.1);
        }
    }
}

.detalhes-marcas {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

.busca-avancada {
    display: block;
    text-transform: uppercase;
    background: #f5f5f5;
    margin: 0 auto;
    margin-bottom: -45px;
    width: 12%;
    margin-top: 40px;
    @include transition(0.3s);

    font: {
        size: 12px;
    }

    color: #a1a1a1;

    &:hover {
        color: #b70a28;
    }
}

.box-principais {
    text-align: center;

    .botao-default {
        margin-top: 20px;
        margin-bottom: 50px;
        display: inline-block;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: $branco;
        text-align: center;
        background: $main-color;

        font: {
            size: 16px;
        }

        @include transition(0.3s);
        @include radius(2px);

        i {
            font-size: 12px;
        }

        &.todo-estoque {
            color: $btn-text;
        }
    }
}

.car-icon {
    display: inline-block;
    width: 20px;
    height: 21px;
    vertical-align: middle;

    &.combustivel {
        background: url(../img/icones/icons_hover.png);
        background-position: -46px 0px;
        padding-left: 23px;
    }

    &.portas {
        background: transparent url("../img/icones/07.png") scroll no-repeat left center;
    }

    &.cor {
        background: transparent url("../img/icones/06.png") scroll no-repeat left center;
    }

    &.cambio {
        background: transparent url("../img/icones/05.png") scroll no-repeat left center;
    }

    &.ano {
        background: url(../img/icones/icons_hover.png);
        background-position: 0 0px;
        padding-left: 23px;
    }

    &.quilometragem {
        background: url(../img/icones/icons_hover.png);
        background-position: -24px 0px;
        padding-left: 23px;
    }

    &.motor {
        background: transparent url("../img/icones/02.png") scroll no-repeat left center;
    }

    &.car {
        background: transparent url("../img/icones/01.png") scroll no-repeat left center;
    }
}

.card-visible {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

.card-carro {
    background-color: #fff;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    border-top: solid 5px $main-color;

    //Animação
    opacity: 0;
    transform: translateY(-20px);
    transition:
        opacity 0.8s ease-out,
        transform 0.8s ease-out;

    .car-icon {
        &.ano {
            background: url(../img/icones/icons_hover.png);
            background-position: 0 0px;
        }

        &.quilometragem {
            background: url(../img/icones/icons_hover.png);
            background-position: -24px 0px;
        }

        &.combustivel {
            background: url(../img/icones/icons_hover.png);
            background-position: -46px 0px;
        }
    }

    &:hover {
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);

        .valor {
            // color: $main-color !important;
        }

        .cifrao {
            // color: $main-color !important;
        }

        .nome-do-carro {
            color: $preto !important;
        }

        .versao {
            color: $preto !important;
        }

        .caixa-foto img {
            transform: scale(1.1);
            /* Aumenta a imagem em 10% */
        }
    }

    .tag-oferta {
        color: white;

        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 140px;
        height: 0;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        border-top: 20px solid $main-color;
        border-right: 15px solid transparent;
        border-left: 15px solid $main-color;

        span {
            position: absolute;
            width: 115px;
            top: -22px;
            left: -10px;
        }
    }

    .botao-default {
        display: block;
        background: $main-color;
        text-transform: capitalize;
        color: #fff;
        text-align: center;
        border: solid 1px #fff;
        font-size: 12px;
        font-weight: 700;
        width: 100%;
        padding: 10px 10px !important;
        margin: 0;

        &:hover {
            background-color: $main-color--darker;
        }

        i {
            font-size: 12px;
        }

        &.ligamos-para-voce {
            background: #21a45c;

            &:hover {
                background: #26d775;
            }
        }
    }

    .container-nome {
        .marca-do-carro {
            display: inline-block;
            width: 100%;
        }

        .padding {
            padding-left: 25px;
        }

        .nome-do-carro {
            color: $preto;
            width: 100%;
            display: flex;
            align-items: center;

            img {
                width: 26px;
                margin-right: 10px;
            }
        }

        .versao {
            text-align: left;
        }

        .versao,
        .tipo-do-carro {
            color: $preto;
            height: 35px;
            text-transform: uppercase;
            margin: 0;
            display: flex;

            font: {
                size: 11px;
            }
        }

        ul.caixa-specs {
            display: flex;

            li {
                color: #575757;
                font-size: 0.8rem;
                padding: 5px 2px;
                display: flex;
                align-items: center;

                svg {
                    width: 22px;
                    height: 22px;
                    fill: #575757;
                }

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }

                span.car-icon {
                    width: auto;
                    line-height: 20px;
                }
            }
        }
    }

    .caixa-whatsapp {
        text-align: center;
        background-color: #5cb85c;
        color: white;
        padding: 10px;
        margin-top: 10px;
    }
}

.bg-carregando {
    background: url("https://retchhh.wordpress.com/wp-content/uploads/2015/03/loading1.gif");
    background-size: cover;
    background-position: center;
}

.caixa-foto {
    position: relative;
    margin-bottom: 15px;
    display: block;
    width: 100%;

    border-bottom: 1px solid #ccc;
    overflow: hidden;

    img {
        width: 100%;
        object-fit: cover;
        transition: transform 0.5s ease-in-out;
    }

    .icon-play {
        position: absolute;
        display: block;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        cursor: pointer;
        background-color: lighten($main-color, 10%);
        font-size: 30px;
        color: $branco;
        padding: 25px 25px 25px 30px;
        border-radius: 60px;
        opacity: 0.9;

        &:hover {
            background-color: lighten($main-color, 40%);
        }
    }

    .local-venda {
        background: #313131;
        color: $branco;
        font-size: 10px;
        padding: 2px 4px;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.5;
    }

    .botao-comparar {
        position: absolute;
        top: 5px;
        right: 5px;
        font-weight: 700;
        color: $branco;
        text-transform: uppercase;
        font-size: 9px;
        display: inline-block;
        @include radius(5px);
        @include transition(0.3s);
        padding: 3px 7px;
        background: $secondary-color;
        opacity: 0.5;

        &:hover {
            background: darken($secondary-color, 10%);
            opacity: 0.9;
        }
    }
}

.caixa-de-valor {
    height: 70px;
    border-top: solid 1px #fefefe;
    padding-left: 50px;
    padding-right: 50px;

    .valor-do-carro {
        .cifrao {
            color: #333;
            font-size: 14px;
            font-weight: normal;
        }

        .valor {
            color: $main-color;
            font-size: 20px;
            font-weight: 900;
        }
    }
}

.car-right-box {
    position: absolute;
}

.box-news {
    padding-top: 70px;
    padding-bottom: 100px;
    background: #f7f7f7;

    input {
        height: 50px;
        @include radius(50px);
        width: 100%;
        border: solid 1px #cccccc;
        padding-left: 20px;
        padding-right: 20px;
    }

    .enviar-contato {
        height: 50px;
        line-height: 50px;
        color: #55401a;
        background: $main-color;
        @include transition(0.3s);
        display: inline-block;
        margin-bottom: 0px;
        margin-top: 0;
        cursor: pointer;
        width: 100%;
        border: none;
        font-size: 18px;
        @include radius(50px);

        &:hover {
            background-color: darken($main-color, 10%);
        }
    }
}

.icone-news {
    display: inline-block;
    vertical-align: top;

    i {
        color: #b70a28;
        font-size: 100px;
    }
}

.texto-news {
    margin-bottom: 65px;
}

.sobre-news {
    display: inline-block;
    width: 45%;
    text-align: left;
    margin-left: 20px;

    .titulo-pagina {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
        color: #b70a28;
    }

    .pequeno-texto-news {
        color: #615f6c;
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        width: 60%;
    }
}

.contato {
    text-align: center;
    margin-bottom: 40px;

    input {
        height: 40px;
        border: solid 1px #aaaaaa;
        @include radius(3px);
        margin-bottom: 20px;
    }

    textarea {
        margin-bottom: 20px;
    }

    .enviar-contato {
        display: inline-block;
        background: $main-color;
        border: none;
        text-transform: uppercase;
        color: $branco;
        cursor: pointer;
        @include transition(0.3s);
        @include radius(50px);
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
            background-color: darken($main-color, 10%);
        }
    }
}

.box-de-atendimento {
    margin-bottom: 50px;

    .click-atendimentos {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .botao-default {
            margin-top: 20px;
            margin-bottom: 0;
            display: inline-block;
            background: #ae0926;
            padding-left: 100px;
            padding-right: 100px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: $branco;
            text-align: center;

            font: {
                size: 16px;
            }

            @include transition(0.3s);
            @include radius(50px);

            i {
                font-size: 12px;
            }

            &:hover {
                background: $main-color;
            }

            &.ligamos-para-voce {
                background: #21a45c;

                &:hover {
                    background: #26d775;
                }
            }

            &.botao-azul {
                background: #2455ae;

                &:hover {
                    background: #3470df;
                }
            }

            &.btn-whatsapp {
                background: #5cb85c;

                &:hover {
                    color: #fff;
                    background-color: #449d44;
                }
            }
        }
    }
}

.lojas {
    text-align: center;

    .botao-default {
        margin-top: 20px;
        margin-bottom: 50px;
        display: inline-block;
        background: #1a1a1a;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: $branco;
        text-align: center;

        font: {
            size: 16px;
        }

        @include transition(0.3s);
        @include radius(50px);

        i {
            font-size: 12px;
        }

        &:hover {
            background: $main-color;
        }
    }
}

.card-loja {
    text-align: center;
    background: $branco;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 12px;
    margin-bottom: 20px;

    img {
        width: 100%;
        max-width: 260px;
        display: inline-block;
        margin-bottom: 25px;
    }

    .nome-da-loja {
        display: block;
        font-weight: bold;
        font-size: 18px;
    }

    .botao-default {
        margin-top: 25px;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        background: $secondary-color;
        font-weight: 900;
        text-transform: uppercase;
        color: $branco;
        text-align: center;

        font: {
            size: 16px;
        }

        @include transition(0.3s);
        @include radius(2px);

        i {
            font-size: 12px;
        }

        &:hover {
            background: $main-color;
        }
    }
}

.banner-servicos {
    margin-top: 20px;
}

.servicos {
    margin-bottom: 60px;

    .card-loja {
        height: 580px;

        img {
            max-width: 360px;
            height: 100%;
            max-height: 170px;
        }
    }

    .texto-servico {
        text-align: center;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .botao-loja {
        margin-bottom: 20px;
    }

    .visite-o-site {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 10px;
        display: inline-block;
        color: #5b5b5b;
        text-decoration: underline;
        font-weight: 900;
    }
}

.box-quem-somos {
    .nome-do-local {
        text-align: center;
        display: inline-block;
        width: 100%;
    }

    .click-atendimentos {
        text-align: center;
        background: #eeeeee;
        padding-top: 50px;
        padding-bottom: 50px;

        .botao-default {
            margin-top: 20px;
            margin-bottom: 0;
            display: inline-block;
            background: #ae0926;
            padding-left: 100px;
            padding-right: 100px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: $branco;
            text-align: center;

            font: {
                size: 16px;
            }

            @include transition(0.3s);
            @include radius(50px);

            i {
                font-size: 12px;
            }

            &:hover {
                background: $main-color;
            }

            &.ligamos-para-voce {
                background: #21a45c;

                &:hover {
                    background: #26d775;
                }
            }

            &.botao-azul {
                background: #2455ae;

                &:hover {
                    background: #3470df;
                }
            }
        }
    }

    .card-loja {
        text-align: center;
        background: $branco;
        border: 1px solid $secondary-color;
        color: $secondary-color;
        padding-top: 50px;
        padding-bottom: 50px;
        height: inherit;

        .texto-servico {
            margin-top: 0;
        }

        .botao-default {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.video-quem-somos iframe {
    width: 100%;
    height: 300px;
}

.container-atendimento {
    background: #f1f1f1;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 15px 0px;

    .btn {
        font-weight: bold;
    }

    .btn-whatsapp {
        color: #fff;
    }
}

.galeria-de-quem-somos {
    .item-galeria {
        text-align: center;
        margin-bottom: 20px;

        img {
            width: 100%;
            max-width: 260px;
            display: inline-block;
        }
    }
}

.texto-sobre {
    /*margin-top: -220px;*/
    margin-bottom: 50px;

    .titulo-pagina {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 22px;
    }

    p {
        font-size: 1rem;
        color: #8d8d8d;
        display: inline-block;
        margin-bottom: 15px;
    }
}

.filtro {
    background: #f1f1f1;
    display: none;
}

.filtro[style*="display: block"] {
    display: flex !important;
}

.box-estoque {
    padding-top: 5px;

    .ordenar-por {
        span {
            display: inline-block;
            width: 100%;
            text-align: left;
            font-size: 11px;
            color: #1b1b1b;
        }
    }

    .ordenar {
        margin-top: 20px;
        margin-bottom: 20px;

        li {
            display: inline-block;
            border-right: solid 1px #aeaeae;
            padding-right: 10px;
            padding-left: 10px;

            a {
                @include transition(0.3s);
                font-size: 14px;
                color: #1b1b1b;

                &:hover {
                    color: $main-color;
                }
            }
        }

        li:first-child {
            padding-left: 0;
        }

        li:last-child {
            border-right: none;
        }
    }

    .mostrar-quantidade {
        margin-top: 15px;

        span {
            display: inline-block;
            font-size: 11px;
            color: #1b1b1b;
        }

        select {
            display: inline-block;
            width: auto;
            margin-left: 10px;
            @include radius(0);
            height: 30px;
            font-size: 11px;
        }
    }
}

.quantidade-de-paginas {
    text-align: right;
    padding-top: 20px;
    padding-bottom: 20px;

    li {
        margin-left: 3px;
        margin-right: 3px;
        display: inline-block;
        background: #f2f2f2;
        @include radius(2px);
        @include transition(0.3s);
        text-align: center;

        a {
            color: #9f9f9f;
            @include transition(0.3s);
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;
        }

        &:hover {
            background: $main-color;

            a {
                color: $branco;
            }
        }

        &.pagina-atual {
            background: $main-color;

            a {
                color: $branco;
            }
        }
    }
}

.form_busca_fixed {
    position: fixed !important;
    top: 0px !important;
    width: 100% !important;
    max-width: unset !important;
    border-radius: 0px 0px 10px 10px !important;
    z-index: 99 !important;
}

.caixa-avancada {
    background: #f7f7f7;
    margin-bottom: 50px;

    .quantidade-encontrada {
        width: 100%;
        background: $main-color;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;

        .quantidade {
            font-size: 24px;
            font-weight: 900;
            color: $branco;
            display: inline-block;
            width: 100%;
        }

        span {
            text-transform: uppercase;
            color: $branco;
            font-weight: 300;
            font-size: 12px;
        }
    }

    .selecionados {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;

        span {
            display: inline-block;
            width: 100%;
            color: #1b1b1b;
            font-size: 14px;
            margin-bottom: 20px;
        }

        .item-selecionado {
            display: inline-block;
            margin-bottom: 20px;
            margin-right: 15px;

            .fechar {
                @include radius(50%);
                display: inline-block;
                width: 20px;
                height: 20px;
                color: $branco;
                font-weight: 900;
                background: $main-color;
                line-height: 20px;
                text-align: center;
                font-size: 14px;
                margin-right: 5px;
            }

            .nome-do-item {
                color: #1b1b1b;
                font-size: 12px;
                display: inline-block;
                width: inherit;
                margin-bottom: 0;
            }
        }
    }

    .tag {
        background: $preto;
        width: 100%;
        display: inline-block;
        color: $branco;
        text-transform: uppercase;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
    }

    .busca {
        li {
            a {
                text-align: center;
                display: block;
                position: relative;
                height: 40px;
                width: 100%;
                line-height: 40px;
                color: #1b1b1b;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 400;
                @include transition(0.3s);

                &:hover {
                    color: $branco;
                    background: $main-color;
                }

                .fa-angle-right {
                    position: absolute;
                    right: 5%;
                    top: 14%;
                    font-size: 25px;
                }

                &.busca-estoque-ativa {
                    color: $branco;
                    background: $main-color;
                }
            }

            .sub-opcoes {
                max-height: 300px;
                overflow-y: auto;
                display: none;
                background-color: $main-color;
                padding: 10px;
                color: $branco;

                li {
                    a {
                        padding-left: 15px;
                        text-align: left;
                        color: $branco;
                    }

                    select {
                        color: #000;
                    }
                }
            }
        }
    }

    .form-estoque {
        padding: 0 10px;

        .form-group {
            margin-bottom: 0px;
            margin-top: 10px;

            .bg-main-color {
                background: $main-color;
                border-radius: 5px;
                padding: 5px 0px;
                margin-top: 10px;
                width: 100%;
            }

            .form-buscar {
                border: none;
                background: none;
                color: white;
            }
        }
    }

    .encontre-aqui {
        background: #1b1b1b;
        padding: 10px;

        .barra-de-pesquisa {
            margin: 0;
            height: 30px;

            .form-inline {
                .form-group {
                    .input-group {
                        input {
                            height: 30px;
                        }

                        .input-group-addon {
                            padding-right: 5px;

                            .botao-estoque-submit {
                                height: 30px;
                            }

                            i {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.box-interna-carro {
    margin-top: 50px;

    .marca-do-carro,
    .nome-do-carro {
        display: inline-block;

        &:hover {
            color: white;
        }
    }

    .marca-do-carro {
        vertical-align: top;
        padding-top: 5px;
        margin-right: 10px;

        img {
            display: inline-block;
        }

        &:hover {
            color: white;
        }
    }

    .nome-do-carro-interna {
        font-family: $lato;
        color: #333;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 300;
        display: inline-block;

        strong {
            font-size: 1.6rem;
            font-weight: 500;
        }
    }

    .fotos-do-carro {
        margin-top: 20px;
        position: relative;

        img {
            width: 100%;
            max-height: 580px;
        }

        .seta-esquerda,
        .seta-direita,
        .seta-direita-mobile,
        .seta-esquerda-mobile {
            z-index: 100;
            color: #fff;
            position: absolute;
            top: 45%;
            display: none;
            width: 50px;
            text-align: center;
            line-height: 50px;
            height: 50px;
            background: transparent;
            border: 0;
            margin: 0;
            padding: 0;
            @include transition(0.3s);
            cursor: pointer;
            font-size: 50px;
        }

        &:hover {

            .seta-esquerda,
            .seta-direita,
            .seta-direita-mobile,
            .seta-esquerda-mobile {
                display: block;
            }
        }

        .seta-direita,
        .seta-direita-mobile {
            right: 0;
        }

        .seta-esquerda,
        .seta-esquerda-mobile {
            left: 0;
        }
    }

    .galeria-thumb {
        background: #e6e6e6;
        padding-top: 10px;
        text-align: center;

        .item-thumbs {
            max-width: 100px;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 10px;

            img {
                display: inline-block;
                cursor: pointer;
                width: 100%;
                max-width: 95px;
            }
        }
    }

    .gallery-medias {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;

        .main-element {
            grid-row: span 2;
        }

        img,
        iframe {
            width: 100%;
        }

        .overlay-last-image {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;

            color: #f1f1f1;

            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.quadro-informacoes-do-carro {
    .item-informacao {
        height: 50px;
        line-height: 50px;
        padding-left: 12px;
        font-size: 14px;
        font-weight: 300;

        img {
            display: inline-block;
            margin-right: 10px;
        }

        &.cinza {
            background: #f7f7f7;
        }
    }
}

.caixa-caracteristica {
    margin-bottom: 50px;

    .caixa-titulo {
        display: inline-block;
        width: 100%;
        padding-left: 15px;
        height: 60px;
        color: $branco;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 25px;
        background: #313131;
        line-height: 60px;
    }

    .tag-principal {
        display: inline-block;
        width: 100%;
        color: $main-color;
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: 900;
    }

    .tag {
        display: inline-block;
        width: 100%;
        padding-left: 20px;
        color: $preto;
        font-size: 16px;
        margin-bottom: 15px;
    }

    .info-caracteristica {
        display: inline-block;
        width: 100%;
        color: $preto;
        font-weight: 400;
        padding-left: 20px;
        font-size: 12px;
        margin-bottom: 30px;
        padding-right: 60px;
    }
}

.compartilhar {
    border-top: solid 1px #aaaaaa;
    padding-top: 10px;
    width: 95%;
    margin: 0 auto;
    margin-top: 30px;

    span {
        font-size: 12px;
    }
}

.compartilhar-btn {
    text-align: center;
    width: 100%;
    @include radius(5px);
    text-transform: uppercase;
    color: $branco;
    font-weight: 300;
    font-size: 14px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;

    &:hover {
        color: $branco;
    }

    &.whatsapp {
        background: #65bc54;
    }

    &.facebook {
        background: #3c6ecb;
    }

    &.youtube {
        background: #dc3333;
    }

    &.twitter {
        background: #52d4f9;
    }

    &.instagram {
        background: #e87e21;
    }
}

.btn {
    &.btn-main {
        background-color: $main-color;

        &:hover {
            color: #fff;
            background-color: darken($main-color, 10%);
        }
    }

    &.btn-outline {
        border: solid 1px #e1e1e1;
        border-radius: 0.375rem;
        font-size: 0.8rem;
        color: #888;
        padding: 11px 18px;
    }

    &.btn-secondary {
        background-color: $main-color;
        padding: 11px 60px;
        font-size: 0.8rem;
        border-radius: 20px;
        border: none;
        font-weight: bold;

        &:hover {
            color: #fff;
            background-color: darken($secondary-color, 5%);
        }
    }

    &.btn-whatsapp {
        background-color: #5cb85c;

        &:hover {
            color: #fff;
            background-color: #449d44;
        }
    }

    &.btn-ligar {
        background-color: #5bc0de;

        &:hover {
            color: #fff;
            background-color: #31b0d5;
        }
    }

    &.btn-lojista {
        background-color: #337ab7;

        &:hover {
            color: #fff;
            background-color: #286090;
        }
    }
}

.texto-reservado {
    margin-top: 15px;

    p {
        font-size: 10px;
        color: #1b1b1b;
        margin-bottom: 0;
    }
}

.lista-de-caracteristicas {
    display: flex;

    li {
        display: flex;
        text-align: center;

        span {
            font-size: 12px;
            color: $preto;
        }
    }

    li:first-child {
        margin-left: 0;
    }
}

.checks {}

.opcional-check {
    width: 100%;
    display: inline-block;
    color: $preto;

    i {
        margin-right: 5px;
    }
}

.marca-da-loja {
    display: inline-block;
    padding: 30px;
    text-align: center;

    img {
        width: 100%;
        display: inline-block;
    }

    border: solid 1px #dedede;
    @include radius(10px);
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: top;
}

.contato-da-loja {
    display: inline-block;

    span {
        display: inline-block;
        width: 100%;
        color: #717171;
        font-size: 12px;
    }
}

.lead-preco {
    border-top: solid 1px #fff;
    background: $main-color;
    text-align: center;

    form {
        background-color: $branco;
        padding-top: 10px;
    }
}

.valor-carro {
    text-align: center;

    .cifrao {
        color: $main-color;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 14px;
    }

    .valor {
        color: $main-color;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.6rem;
    }

    .valor-antes {
        text-decoration: line-through;
    }
}

.de {
    color: #6c6c6c;
    font-size: 1.2rem;
}

.valor-de {
    color: #6c6c6c;
    font-size: 14px;
}

.tag-lead-interna {
    text-transform: uppercase;
    color: $branco;
}

.box-inputs {
    padding-left: 10px;
    padding-right: 10px;
}

.lead-interna-carro {
    input {
        border: none;
        @include radius(0);
        margin-bottom: 12px;
        color: #1b1b1b;
    }

    textarea {
        border: none;
        @include radius(0);
        margin-bottom: 12px;
        height: 100px;
        color: #1b1b1b;
    }
}

.tag-lead-menor {
    color: $branco;
    font-size: 12px;
    font-weight: 800;
    display: inline-block;
}

.box-radios {
    .radio {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;

        label {
            color: #615f6c;
            font-size: 11px;
            font-weight: 300;
        }
    }
}

.btn-simulacao {
    background-color: $main-color;
    text-transform: uppercase;
    color: $branco;
    font-weight: 800;
    text-align: center;
    display: inline-block;
    width: 100%;
    border-radius: 0px !important;

    &:hover {
        color: $branco;
        background-color: darken($main-color, 10%);
    }
}

.enviar-lead-interna {
    margin: 15px auto;
    display: inline-block;
    background: $main-color;
    color: $branco;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    font-weight: 800;
    width: 95%;

    font: {
        size: 16px;
    }

    @include transition(0.3s);
    @include radius(1px);

    i {
        font-size: 12px;
    }

    &:hover {
        background: $main-color--darker;
        color: #000 !important;
        transition: all 0.5s ease-in;
    }
}

.itens-do-painel {
    padding-left: 70px;
    padding-right: 70px;

    li {
        color: #000;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 10px;
        list-style: square;
    }
}

.estoque-da-loja {
    background: #1b1b1b;
    padding-top: 20px;
    padding-bottom: 20px;

    .marca-do-estoque {
        width: 100%;
        background: $branco;
        @include radius(10px);
        height: 120px;
        text-align: center;
        line-height: 120px;

        img {
            width: 100%;
            display: inline-block;
            max-width: 150px;
        }
    }

    span {
        display: block;
        width: 100%;
        color: $branco;
        font-size: 12px;

        &.nome-do-estoque {
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    .endereco-da-loja-estoque {
        margin-top: 16px;
    }

    p {
        color: $branco;
        font-size: 12px;
        margin-top: 20px;
    }

    .veja-mais {
        color: $branco;
        text-decoration: underline;
        font-size: 11px;
    }

    .ms {
        margin-top: 20px;
    }
}

.banner-estoque-loja {
    img {
        display: inline-block;
        width: 100%;
        max-width: 525px;
    }
}

.numero-atendimento {
    margin-top: 20px;

    .icone-atendimento {
        display: inline-block;
        margin-right: 10px;
        color: #fff12c;

        i {
            font-size: 20px;
        }

        .fa-whatsapp {
            color: #3bec7e;
        }
    }

    .numero-do-atendimento {
        display: inline-block;
        text-align: center;
        color: $branco;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 12px;
    }
}

.modal-header {
    background: $main-color;
    color: $branco;
    text-transform: uppercase;
    font-weight: 900;
}

.modal-body {
    .form-ajax {
        input {
            @include radius(0);
            margin-bottom: 15px;
            margin-top: 5px;
        }
    }

    &.no-padding {
        padding: 0px;
    }
}

.g-recaptcha,
.submit-action {
    border: none;
    width: 250px;
    max-width: 100%;
    display: inline-block;
    background: $main-color;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    color: $branco;
    text-align: center;

    font: {
        size: 16px;
    }

    @include transition(0.3s);
    @include radius(50px !important);

    i {
        font-size: 12px;
    }

    &:hover {
        background: $main-color;
    }
}

.box-radios {
    color: black;
}

#modal-financiamento {
    select {
        height: 43px;
        margin-top: 5px;
        border-radius: 0;
    }
}

#modal-exit {
    .modal-content {
        width: 500px;
        height: 500px;
    }

    .modal-body {
        width: 100%;
        height: 100%;

        .descricao {
            p {
                color: white;
                margin-top: 25px;
                font-size: 15px;
                font-style: italic;
                padding-right: 43px;
                line-height: 19px;
            }

            button {
                background-color: white;
                color: #005aab;
                font-size: 14px;
                padding: 6px 14px;
                margin-top: 10px;
                font-style: italic;
                font-weight: 800;
            }
        }

        #form-popup {
            padding: 0px 18px 0px 39px;
            margin-top: 30px;

            p {
                color: #005aab;
                text-align: center;
                font-size: 12px;
                margin-bottom: 5px;
            }

            .bg-amarelo {
                padding: 6px;
                background-color: #f1ce00;
                border-radius: 5px;
            }

            .borda {
                border: solid 1px #005aab;
                border-radius: 5px;
                padding: 12px;
            }

            .form-group {
                margin-bottom: 0px !important;

                input {
                    margin-bottom: 0px !important;
                    text-align: center;
                    border-radius: 10px;
                    font-size: 12px;
                }

                .submit-action {
                    background: rgb(228, 55, 51);
                    background: linear-gradient(90deg, rgba(228, 55, 51, 1) 0%, rgba(155, 17, 15, 1) 100%);
                    border-radius: 5px !important;
                    font-weight: 600;
                }
            }
        }
    }
}

.diferenciais {
    position: relative;

    .seta-esquerda,
    .seta-direita {
        z-index: 100;
        color: black;
        position: absolute;
        top: 45%;

        width: 50px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        @include transition(0.3s);
        cursor: pointer;
        font-size: 50px;
    }

    .seta-direita,
    .seta-direita-mobile {
        right: -35px;
    }

    .seta-esquerda,
    .seta-esquerda-mobile {
        left: -35px;
    }

    padding-top: 50px;

    >.container {
        padding: 0 50px;
    }

    .card-diferencial {
        text-align: center;
        padding: 10px 40px;

        img {
            display: inline;
        }
    }

    p {
        color: $secondary-color;
    }

    p.descricao {
        text-transform: uppercase;
    }

    .diferencial-titulo {
        //border: 1px solid $secondary-color;
        max-width: 90%;
        margin: 5px auto;
        font-weight: bold;
        font-size: 20px;
    }

    h2 {
        color: $main-color;
        margin-bottom: 15px;

        span {
            color: $secondary-color;
        }
    }
}

.block-overflow {
    overflow: hidden;
}

.no-btn {
    background: 0 0;
    border: none;
}

.box-login {
    .card-login {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;

        &-body {
            flex: 1 1 auto;
            padding: 1.25rem;
        }
    }

    .login-info {
        @media (max-width: 767px) {
            margin-top: 15px;
            text-align: center;
        }
    }
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mfp-iframe-scaler {
    .btn-medium {
        display: inline-block;
        width: 160px;
        height: 35px;
        padding: 0;
        line-height: 35px;

        font-size: 13px;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        color: $cta-text-color;
        text-align: center;
        text-transform: uppercase;
    }

    .btn-full {
        width: 100%;
    }

    .btn-main-color {
        background-color: $main-color;

        &:hover {
            background-color: $main-color--lighter;
        }
    }

    a {
        position: absolute;
    }
}

/*------------------------------------*
 * #BREADCRUMB */
/*------------------------------------ */

.breadcrumb {
    margin-bottom: 0px;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}

.breadcrumb-list {
    list-style: none;
    padding: 15px 0;
    border-bottom: solid 1px #e1e1e1;

    .divider {
        color: $dark-gray;
        font-size: 14px;
    }

    li {
        display: inline;

        a,
        a:link,
        a:visited {
            color: $dark-gray;
            font-size: 14px;
        }

        a:hover {
            color: $main-color;
        }
    }
}

.container-modal-telefones {
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 15px;
    margin-bottom: 10px;

    h4 {
        text-align: left;
        font-weight: bold;
    }
}

.container-modal-telefones-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    span {
        font-size: 1.35rem !important;
        font-weight: 300;
    }

    .btn-default {
        border-width: 2px !important;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.8rem !important;
    }
}

/*------------------------------------*
 * #DEPOIMENTOS */
/*------------------------------------ */

.box-depoimentos {
    background: #f1f1f1;

    .depoimento {
        background: #fff;
        border-radius: 10px;

        .texto {
            color: #8d8d8d;
        }

        .foto {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
        }

        .nome {
            font-weight: bold;
        }
    }
}

/*------------------------------------*
 * #OFERTAS */
/*------------------------------------ */

.card-ofertas-interna {
    background: #c2edc2;
    border: 3px solid;
    border-radius: 10px;
    color: #3a693c;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.card-ofertas {
    height: 100%;

    .parcelas {
        font-size: 1rem !important;
        font-weight: normal !important;
    }

    .card-image {
        background-size: cover !important;
        background-position: center !important;
    }

    box-shadow: 0px 0px 9px -5px #000;
    position: relative;

    .card-content-image {
        img {
            max-width: 100%;
        }
    }

    .valores {
        position: absolute;
        bottom: -40px;
        background: $main-color;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0px;
            padding: 0px;
            font-size: 1.8rem;
            line-height: 1.8rem;
            font-weight: bold;
            color: #fff;
        }

        .preco-de {
            color: #333;
            text-decoration: line-through;
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.1rem;
            color: #fff;
        }

        .card-ctas {
            margin-left: 10px;
            padding-left: 10px;
            border-left: solid 1px #fff;

            a {
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: lighter;
                color: #fff;
                text-transform: uppercase;
            }
        }
    }
}

/*------------------------------------*
    #Blog*/
/*------------------------------------*/

.blog {
    padding-bottom: 50px;
    padding-top: 85px;

    article {
        margin-bottom: 20px;
        border-bottom: solid 1px #9e9e9e;
        padding-bottom: 22px;

        .col-md-5 {
            height: 250px;
            overflow: hidden;
        }

        .post-share {
            background-color: #4caf50;
            color: #fff;
            padding: 10px 15px;
        }

        .post-share:hover {
            background-color: #479e4b;
        }

        .post-link {
            background-color: #000;
            color: #fff;
            padding: 10px 15px;
        }

        .post-link:hover {
            background-color: #333;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .post-title {
        font-size: 24px;
        margin: 0px auto 10px;
        text-align: left;
        color: $cta-text-color;

        a {
            color: inherit;
        }
    }

    .post-image {
        width: 50%;
        margin: 0px 30px 30px 0px;
        float: left;

        img {
            width: 100%;
        }
    }

    .post-author {
        font-weight: normal;
        margin: 0px 0 20px;
        color: #bbb;

        &+p {
            text-align: justify;
        }
    }

    .text-center {
        margin-top: 20px;
        padding-top: 20px;
        border-top: solid 1px #9e9e9e;
        text-align: center;

        a {
            color: #000;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.box-noticias {
    .text-center {
        padding-top: 20px;
        border-top: solid 1px #9e9e9e;

        a:hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 767px) {
    .blog {
        padding: 85px 30px 50px;
    }

    .noticias {
        display: block;

        .linha {
            display: block;
        }

        .list-noticias {
            margin-top: 30px;
            padding: 0 15px;
        }
    }

    .main-noticia {
        padding: 0 15px;
    }

    .item-noticia {
        margin-top: 15px;
    }
}

/* GRUPO GAMA */

.grupo-gama {
    img {
        margin-bottom: 10px;
        padding: 5px 10px;
        border: solid 1px #e7e6e6;
        border-radius: 15px;
        max-width: 100%;
        transition: all 0.5s ease-in;

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            transition: all 0.5s ease-in;
        }
    }
}

/* Quando o input estiver selecionado */
.btn-options-interna:checked+label {
    /* Estilos quando o input está selecionado */
    background-color: #d9a420 !important;
    border: solid 1px #d9a420 !important;
    font-weight: bold;
    color: #000 !important;
}

/* Quando o mouse está sobre o input */
.btn-options-interna:hover+label {
    /* Estilos quando o mouse está sobre o input */
    background-color: #d9a420 !important;
    border: solid 1px #d9a420 !important;
    color: #000 !important;
}

.prosseguir-contrato {
    margin-top: 15px;
    margin-bottom: 10px !important;
    display: inline-block;
    background: #000;
    color: #fff !important;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: none !important;
    text-transform: uppercase;
    font-weight: 800;
    transition: all 0.5s ease-in;

    &:hover {
        background: #d9a420;
        color: #000 !important;
        transition: all 0.5s ease-in;
    }
}

.form-contrato-container {
    background-color: #ecf0f1;
    border-radius: 10px;

    form {
        padding: 10px;
    }
}

.form-contrato-container-footer {
    background: #d9a420;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
}

.text-alerta-aplicativos {
    font-size: 0.7rem;
}

.garantia {
    background-color: rgba(0, 0, 0, 0.5);
    width: 130px;
    height: 20px;
    margin-top: 5%;
    margin-left: 5%;
    position: absolute;
    border-radius: 15px;
    color: white;
    font-size: small;
    font-weight: bold;
    text-align: center;
}

//Cookie container
.cookie {
    //background com fundo preto transparente
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;

    .texto {
        color: #fff;
        font-size: 14px;
        font-family: $lato;
    }

    .botao {
        font-family: $lato;
    }
}

.cta-search-bar {
    background-color: #e30613;
    padding: 10px 0px;

    .search-bar-group {
        display: flex;
        align-items: center;

        .barra-de-pesquisa-header {
            border-radius: 10px 0px 0px 10px;
            outline: none;
            border: none;
            padding: 10px 10px 10px 10px;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;

            &::placeholder {
                color: #999999; // Defina a cor desejada
                font-weight: 400; // Defina a espessura da fonte
                opacity: 1; // Controle a opacidade
            }
        }

        button {
            border: none;
            cursor: pointer;
            background: #fff;
            padding: 8.5px;
            margin-top: 0px;
            border-radius: 0px 10px 10px 0px;
        }
    }
}

.search-group-scrollavel {
    display: flex;
    align-items: center;

    .search-bar__input {
        outline: none;
        text-transform: uppercase;
        padding: 10px;
        font-size: 12px;
        border: 1px solid #d7d7d7;
        border-radius: 4px 0px 0px 4px;
        margin-bottom: 0px !important;
        border-right: none;

        &::placeholder {
            color: #999999; // Defina a cor desejada
            font-weight: 400; // Defina a espessura da fonte
            opacity: 1; // Controle a opacidade
        }
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
        border: solid 1px #d7d7d7;
        padding: 7px;
        border-radius: 0px 4px 4px 0px;
        border-left: none;

        i {
            font-size: 1.2rem !important;
        }
    }
}

.menu-scrollavel {
    width: 200px;

    li {
        padding: 0px !important;
        margin-bottom: 0px !important;

        a {
            padding-bottom: 0px !important;
            color: #e30613;
            font-weight: 500;
            font-size: 0.85rem;
        }
    }

    hr {
        margin: 7px 0px !important;
    }
}

.container-home {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    text-align: center;
}

.filter-home {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
}

.filter-itens {
    margin-right: 20px;
}

.filter-itens button {
    background-color: #ffffff;
    color: #000000;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.filter-itens button:hover {
    background-color: #e30613;
    color: #ffffff;
}

.arrow-down {
    transform: translateY(-50%) rotate(90deg);
}

.carousel-item .col {
    flex: 0 0 20%;
    /* Exibe 5 itens por slide, cada item ocupa 20% do espaço */
    max-width: 20%;
}

.item-marcas-carousel img {
    max-width: 100%;
    height: auto;
}

.logo-custom-zeekr {
    max-height: 110px;
    margin-top: 110px;
    margin-bottom: 110px;
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .logo-custom-zeekr {
        max-height: 70px;
    }
}

//QUEM SOMOS

.values {
    background-color: #ffffff;
}

.values-text {
    h2 {
        color: #24292b;
        font-weight: 500;
        font-family: "ZeekrHeadline", sans-serif;
    }

    p {
        color: #24292b;
        font-family: "ZeekrText", sans-serif;
    }
}

#valuesCarousel {
    h5 {
        color: #24292b;
        font-family: "ZeekrText", sans-serif;
        font-weight: 700;
    }

    p {
        color: #24292b;
        font-family: "ZeekrText", sans-serif;
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: black;
    background-image: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-control-prev-icon::after,
.carousel-control-next-icon::after {
    content: "";
    display: inline-block;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 5px;
}

.carousel-control-prev-icon::after {
    transform: rotate(135deg);
}

.carousel-control-next-icon::after {
    transform: rotate(-45deg);
}

.grupo-geely-holding {
    font-family: "ZeekrHeadline", sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: #24292b;
}

.text-geely {
    font-family: "ZeekrText", sans-serif;
    color: #24292b;
    font-size: 20px;
}

.custom-link {
    color: #24292b;
    font-size: 20px;
}

.custom-link:hover {
    color: #24292b;
}

@media (max-width: 768px) {
    .grupo-geely-holding {
        font-size: 30px;
        padding-bottom: 15px;
    }
}

.custom-form-wrapper {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
}

.form-contato {
    font-family: "ZeekrHeadline", sans-serif;
    color: #24292b;
    font-size: 60px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.custom-form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.custom-input,
.custom-select,
.custom-textarea {
    border: 1px solid #ccc;
    padding: 15px;
    font-size: 1rem;
    outline: none;
    transition:
        border-color 0.3s,
        transform 0.3s;
    resize: none;
    border-radius: 0;
    width: 100%;
}

.custom-input::placeholder,
.custom-select::placeholder,
.custom-textarea::placeholder {
    color: #888;
    font-size: 1rem;
    position: absolute;
    left: 15px;
    top: 1.1rem;
    transition: 0.3s;
    pointer-events: none;
}

.custom-input:focus::placeholder,
.custom-select:focus::placeholder,
.custom-textarea:focus::placeholder {
    font-size: 0.8rem;
    top: 5px;
    left: 15px;
    transform: translateY(0);
}

.custom-input:focus,
.custom-textarea:focus,
.custom-select:focus {
    border-color: #000;
}

.custom-form-row {
    display: flex;
    gap: 15px;
}

.custom-form-row .custom-form-group {
    flex: 1;
}

.custom-form-check {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}


.custom-form-check input {
    margin-right: 10px;
}

.custom-submit-btn {
    width: 230px;
    background-color: #BDBFBF;
    color: #fff;
    margin-top: 50px;
    padding: 15px 60px;
    border: none;
    cursor: not-allowed;
    transition: background-color 0.3s;
}

.custom-submit-btn.active {
    background-color: #333;
    cursor: pointer;
}

.custom-submit-btn.active:hover {
    background-color: #555;
}

.custom-error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
}

.custom-textarea-wrapper {
    position: relative;
}

.custom-textarea-wrapper .char-counter {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 0.8rem;
    color: #888;
}

//INTERNA
.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: center;
    z-index: 999;
    padding: 20px;
    box-sizing: border-box;
}

.overlay-text h1,
.overlay-text h2 {
    font-family: "ZeekrHeadline", sans-serif;
    font-size: 4rem;
    font-weight: 500;
}

.overlay-text p {
    font-family: "ZeekrHeadline", sans-serif;
    font-size: 2rem;
    margin-bottom: 10px;
}

@media (min-width: 600px) and (max-width: 1024px) {
    .overlay-text {
        padding: 100px;
    }

    .overlay-text h1,
    .overlay-text h2 {
        font-size: 6vw;
    }

    .overlay-text p {
        font-size: 1.5rem;
    }
}

@media (max-width: 599px) {
    .overlay-text {
        height: 120vh;
    }

    .overlay-text h1,
    .overlay-text h2 {
        font-size: 9vw;
    }

    .overlay-text p {
        font-size: 5vw;
    }
}

.hero-btn {
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1;
    border-radius: 2px;

    .btn {
        border-radius: 3px;
    }

    a {
        font-size: 1rem;
        padding: 10px 30px;
    }
}

.heading-interna {
    font-size: 2.5vw;
    font-family: "ZeekrHeadline", sans-serif;
    color: #24292b;
}

@media (max-width: 599px) {
    .heading-interna {
        font-size: 6.5vw;
    }
}

.container-controles {
    padding: 0px 60px 40px 60px;
    font-size: 20px;
}

@media (max-width: 599px) {
    .container-controles {
        padding: 0px 60px 40px 0px;
        font-size: 20px;
    }
}

.cor {
    border-radius: 60px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.cor-1 {
    background-color: #323237;
}

.cor-2 {
    background-color: #6d6d6d;
}

.cor-3 {
    background-color: #ffffff;
    border: solid 1px #e4e4e4;
}

.cor-4 {
    background-color: #bccae0;
}

.cor-5 {
    background-color: #ed8733;
}

.cor-x1 {
    background-color: #FFFFFF;
    border: solid 1px #e4e4e4;
}

.cor-x2 {
    background-color: #6D6D73;
}

.cor-x3 {
    background-color: #E1DCD6;
}

.cor-x4 {
    background-color: #70888C;
}

.elementos-externos-texto {
    background: #121215;
    padding: 0px 104px;

    p {
        font-weight: 300;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .elementos-externos-texto {
        padding: 50px;

        h3 {
            font-size: 1rem;
        }

        p {
            font-size: 0.9rem;
        }
    }
}

@media (max-width: 599px) {
    .elementos-externos-texto {
        padding: 60px;
    }
}

#interna-section-1 {
    font-size: 2.5vw;
    font-family: "ZeekrHeadline", sans-serif;
    color: #24292b;
    padding: 160px 0;
    line-height: 1.2em;
    font-weight: 500;

    .container {
        font-family: "ZeekrHeadline", sans-serif;
    }
}

@media (max-width: 599px) {
    #interna-section-1 .container {
        font-size: 7vw;
        line-height: 1;
    }
}

#interna-section-2 {
    background-color: #fff;
    color: #24292b;
    padding: 120px 0;
}

#interna-section-3 {
    background-color: #fff;
    color: #24292b;
    padding: 120px 0;
}

#interna-section-4 {
    background-color: #000;
    color: #fff;
    padding: 120px 0;

    .heading {
        height: 160px;
        font-family: "ZeekrHeadline", sans-serif;
    }

    .controles {
        margin-top: 60px;
    }

    .controle {
        border-top: solid 2px #666666;
        padding-top: 10px;
        color: #666666;
        cursor: pointer;

        &:hover {
            border-top: solid 2px #fff;
            color: #fff;
        }

        //e .active

        &.active {
            border-top: solid 2px #fff;
            color: #fff;
        }
    }
}

@media (max-width: 599px) {
    .controles {
        justify-content: center;
    }

    .controle-carroceria {
        min-width: 80vw;

    }
}

#interna-section-5 {
    background-color: #fff;
    color: #24292b;
    padding: 120px 0;

    .cor-1 {
        background: linear-gradient(to bottom, #323237 50%, #eb712e 50%);
        margin-right: 10px;
    }

    .cor-2 {
        background: linear-gradient(to bottom, #fff 50%, #566580 50%);
        border: solid 1px #e4e4e4;
    }

    .cor-1x {
        background: linear-gradient(to bottom, #323237 50%, #6D6D6D 50%);
        margin-right: 10px;
    }

    .cor-2x {
        background: linear-gradient(to bottom, #FFFFFF 50%, #566580 50%);
        border: solid 1px #e4e4e4;
    }
}

#interna-section-6 {
    background-color: #fff;
    color: #24292b;
    padding: 120px 0;
}

#interna-section-7 {
    background-color: #000;
    color: #fff;
    padding: 120px 0;

    .heading {
        height: 160px;
        font-family: "ZeekrHeadline", sans-serif;
    }

    .controles {
        margin-top: 60px;
    }

    .controle {
        border-top: solid 2px #666666;
        padding-top: 10px;
        color: #666666;
        cursor: pointer;

        &:hover {
            border-top: solid 2px #fff;
            color: #fff;
        }

        //e .active

        &.active {
            border-top: solid 2px #fff;
            color: #fff;
        }
    }
}

@media (max-width: 599px) {
    .controle-tecnologia {
        min-width: 80vw;
    }
}

#interna-section-8 {
    background-color: #121215;
    color: #ffffff;
    padding: 120px 0;
    font-family: "ZeekrHeadline", sans-serif;
    text-align: center;

    .heading-title {
        font-size: 2.5vw;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
    }

    .container-controles {
        margin-bottom: 50px;
    }

    p {
        font-size: 2.5vw;
        color: #ed8733;
        text-align: center;
        border-top: 1px solid #ed8733;
        padding: 20px 0;
        margin: 0;
    }

    p:last-of-type {
        border-bottom: 1px solid #ed8733;
    }
}

@media (max-width: 599px) {
    #interna-section-8 {
        .heading-title {
            font-size: 2rem;
        }

        p {
            font-size: 1.5rem;
        }
    }
}

@media (max-width: 320px) {
    #interna-section-8 {
        .heading-title {
            font-size: 1.8rem;
        }
    }
}

#interna-section-9 {
    background-color: #121215;
    color: #ffffff;
    padding: 120px 0;
    font-family: "ZeekrHeadline", sans-serif;
    text-align: center;
}

#interna-section-10 {
    background-color: #121215;
    color: #ffffff;
    padding: 120px 0;
    font-family: "ZeekrHeadline", sans-serif;
    text-align: center;

    .container-imagem {
        font-family: "ZeekrText", sans-serif;
        color: #333;
        background-color: #fff;
    }
}

#interna-section-11 {
    background-color: #ffffff;
    color: #000000;
    padding: 120px 0;

    .container-imagem {
        font-family: "ZeekrText", sans-serif;
        color: #333;
        background-color: #f1efeb;
    }

    .heading {
        height: 160px;
        font-family: "ZeekrHeadline", sans-serif;
    }

    .controles {
        margin-top: 60px;
    }

    .controle {
        border-top: solid 2px #999999;
        padding-top: 10px;
        color: #999999;
        cursor: pointer;

        &:hover {
            border-top: solid 2px #000000;
            color: #000000;
        }

        //e .active

        &.active {
            border-top: solid 2px #000000;
            color: #000000;
        }
    }
}

@media (max-width: 599px) {
    #interna-section-11 {
        .heading {
            height: 40px;
        }

        .controle-cockpit {
            min-width: 80vw;
        }
    }
}

@media (max-width: 320px) {
    #interna-section-11 {
        .heading {
            height: 60px;
        }
    }
}

//NOTICIAS

.titulo-pagina {
    font-size: 65px;
    font-weight: 500;
    color: #24292B;
    text-align: center;
    margin-top: 110px;
    margin-bottom: 110px;


    @media (max-width: 1024px) {
        font-size: 55px;
    }


    @media (max-width: 767px) {
        font-size: 40px;
    }
}

.message-empty {
    color: #24292B;
    font-size: 50px;
    margin-bottom: 100px;

    @media (max-width: 1024px) {
        font-size: 40px;
    }


    @media (max-width: 767px) {
        font-size: 20px;
    }
}

.page-content {
    margin-bottom: 100px;
}

.media-list {
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.titulo-noticia {
    font-size: 24px;
    color: #262626;
    margin-bottom: 50px;

    @media (max-width: 767px) {
        font-size: 20px;
    }
}

.titulo-pagina-noticia {
    font-family: "ZeekrHeadline", sans-serif;
    font-size: 36px;
    color: #24292B;
    text-align: start;
    margin-top: 100px;
    margin-bottom: 50px;

    @media (max-width: 767px) {
        font-size: 28px;
    }
}

.video-noticia {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: 5px;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}