header {
    background: $branco;
    width: 100%;
    z-index: 999;
}

.padding-top {
    padding-top: 71px;
}

.header-head-bar {
    height: 71px;
}

.logo {

    display: flex;
    align-items: center;

    a {
        display: table;

        img {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

    }
}

.icon-midia {
    background: url(../img/icones/icones_redes.png);
    padding: 10px 16px 10px 16px;

    &.facebook {
        background-position: -84px -7px;
    }

    &.instagram {
        background-position: -47px -7px;
    }

    &.youtube {
        background-position: -10px -7px;
    }

    &.twitter {
        background-position: -121px -7px;
    }

    &.small {
        background-image: url(../img/icones/icones_redes_small.png);
        padding: 10px 12px 0px 12px;

        &.facebook {
            background-position: -48px 0px;
        }

        &.instagram {
            background-position: -26px 0px;
        }

        &.youtube {
            background-position: -3px 0px;
        }

        &.twitter {
            background-position: -72px 0px;
        }
    }

}

.header-head-bar {
    display: flex;
    align-items: center;

    ul {
        order: 0;

        li {
            padding: 5px 5px;
        }
    }

    .btn {

        color: $branco;
        padding: 5px 25px;

        &.btn-ligar {
            background-color: darken($main-color, 10%);
            color: $main-color;
            font-weight: 600;
            text-transform: uppercase;
            font-size: .8rem;
            width: auto;

            &:hover {
                color: unset;
                background-color: unset;
            }

        }

        &.btn-ligar-header {
            background-color: unset;
            color: $main-color;
            font-weight: 600;
            text-transform: uppercase;
            font-size: .8rem;
            width: fit-content;
            padding: 5px 5px !important;

            &:hover {
                color: unset;
                background-color: unset;
            }

        }


        i {
            margin-right: 3px;
        }
    }

    .bg-white {
        background: $branco;
        margin-left: 40px;
        order: 1;

        ul {
            margin: 8px 10px 0px 10px;

            li {
                padding: 5px 0px;
            }
        }
    }
}

.bg-main-color {
    background-color: $main-color;
}

.bg-secondary-color {
    background-color: $secondary-color;
}

.header-head-info {
    ul {
        float: right;
        margin: 10px 0px;

        li {
            font-size: 14px;

            a {
                color: white;

                &.social-media {
                    //color: $main-color;
                    padding: 10px 0px;
                    //border: solid 1px $main-color;
                    //border-radius: 5px;
                    width: 10px;
                    display: inline-block;
                    text-align: center;
                    box-sizing: content-box;
                }
            }
        }
    }
}

.barra-de-pesquisa {
    width: 100%;
    margin-bottom: 10px;

    .form-inline {
        width: 100%;

        .form-group {
            width: 100%;

            .input-group {
                border: solid 1px $branco;
                border-radius: 5px;
                width: 100%;

                input {
                    background: #fff;
                    width: 100%;
                    height: 40px;

                    outline: none;
                    box-shadow: none;
                    border: 1px solid $main-color;
                }

                input::placeholder {
                    color: $main-color;
                }

                .input-group-addon {
                    background: $main-color;
                    border: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    outline: none;
                    box-shadow: none;
                    padding: 0;

                    .border-left {
                        width: 1px;
                        height: 24px;
                        border-left: solid 1px #b7b7b7;
                        float: left;
                        margin-top: -2px;
                    }

                    .text-addon {
                        margin-top: 3px;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        background: none;
                        height: 15px !important;
                    }

                    .fa-search {
                        color: white;
                    }
                }
            }
        }
    }
}

.ofertas-header {
    margin-top: 15px;
    font-style: italic;
    font-size: 18px;

    i {
        color: red;
    }
}

.ofertas-header-mobile {
    background: #d5ab3b;

    font-style: italic;
    font-size: 12px;

    i {
        color: red;
    }

    p {
        margin: 5px 0px;
    }
}

.fa-bars {
    color: $main-color;
}

.cont-acesso {
    background: white;
    border-radius: 10px;
    padding: 0px 30px 20px 30px;
    position: absolute;
    right: -15px;
    top: 40px;
    width: 190px;
    z-index: 999;
    box-shadow: 0px 0px 15px grey;

    .arrow-up {
        position: absolute;
        top: -10px;
        right: 30px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-bottom: 10px solid white;
    }

    .col-md-12 {
        margin-top: 20px;
    }

    h4 {
        margin-bottom: 5px;

        .italic {
            font-style: italic;
        }
    }
}


/*------------------------------------*
 * #SOCIAL-MEDIAS */
/*------------------------------------ */

.social-medias {
    float: right;
    margin-top: 10px;

    @media screen and (max-width: em(1000)) {
        display: none;
    }

    &--mobile {
        float: none;
        border-left: none;
        text-align: center;
    }

    li {
        display: inline-block;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: inline-block;
        width: 30px;
        height: 30px;

        @media screen and (min-width: 1000px) {
            i {
                color: $main-color;
            }
        }



        /*
    background-image: url("../img/icones/iconebranco.png");

    @include transition(background 0.3s);

    &.facebook {
      background-position: 0 0;
    }

    &.instagram {
      background-position: (-38px) 0;
    }

    &.twitter {
      background-position: (-77px) 0;
    }

    &.youtube {
      background-position: (-115px) 0;
    }
  */
    }
}

/*------------------------------------*
 * #SOCIAL-MEDIAS-HEADER */
/*------------------------------------ */

.social-medias-header {
    float: right;
    display: flex;
    align-items: center;

    li {
        display: inline-block;

        a {
            color: $main-color;

            &:hover {
                color: unset;
            }
        }
    }
}

/*------------------------------------*
 * #MENU-HOVER*/
/*------------------------------------ */

.menu-top-content-nav_items a {
    font-size: 15px;
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    /* Espaçamento inferior */
    color: #FFFFFF;
    margin-left: 48px;


}

/*------------------------------------*
/* Estilos para novo menu, menu interativo e versões para outras telas */
/*------------------------------------ */
form {
    padding: 0;
    margin: 0;
    width: 100%;

}

.search-bar__input::placeholder {
    color: black;
    /* Cor desejada para o placeholder */
    font-weight: bolder;
}

.fixed-menu {
    position: fixed;
    top: -130px;
    /* Fora da tela no início */
    width: 100%;
    background: white;
    z-index: 1050;
    transition: top 0.5s ease;
    /* Transição suave */
}


.fixed-search-bar .search-bar__input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: black;
    font-weight: bolder;
    border: none;
    outline: none;
}

#menu-original.hide {
    display: none;
}

#menu-original-btn.hide {
    display: none;
}


@media (width: 500px) {
    .fixed-menu {
        position: fixed;
        top: 0;
        width: 100%;
        background: white;
        z-index: 1050;
        padding: 10px;

    }


}

/** Menu Scroll **/

form {
    padding: 0;
    margin: 0;
    width: 100%;

}

.search-bar__input::placeholder {
    color: black;
    /* Cor desejada para o placeholder */
    font-weight: bolder;
}

.fixed-menu {
    position: fixed;
    top: -130px;
    /* Fora da tela no início */
    width: 100%;
    background: white;
    z-index: 1050;
    transition: top 0.5s ease;
    /* Transição suave */
}


.fixed-search-bar .search-bar__input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: black;
    font-weight: bolder;
    border: none;
    outline: none;
}

#menu-original.hide {
    display: none;
}

#menu-original-btn.hide {
    display: none;
}


.search-icon {
    color: $main-color;
    font-size: 1.2rem;
    //transform: translateY(-50%) rotate(80deg); 

}

/* Dimensões para lista do menu header  */
@media (max-width: 1180px) {
    .menu-original {
        max-width: 100%;
    }

    .menu-inteiro1 {
        font-size: 10px;
        width: 100%;

    }

}

@media (max-width: 1080px) {
    .menu-original {
        max-width: 100%;
    }

    .menu-inteiro1 {
        font-size: 10px;
        width: 100%;

    }

}

@media (max-width: 980px) {
    .menu-original {
        max-width: 100%;
    }

    .menu-inteiro1 {
        font-size: 10px;
        width: 100%;

    }

}


/* Estilo para mobile */
@media (max-width: 768px) {
    .search-icon {
        //transform: translateY(-30%) rotate(80deg); 
    }

    .search-bar-menu-interact::placeholder {
        font-size: 10px;
    }


}

@media (width: 500px) {
    .fixed-menu {
        position: fixed;
        top: -100;
        width: 100%;
        background: white;
        z-index: 1050;
        padding: 10px;

    }


}

.menu-toggle {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10000;
}


.overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 2;
    padding: 0 5%;
}


.overlay-text-header p {
    font-family: "ZeekrHeadline", sans-serif;
    font-size: 4rem;
    text-align: center;
    margin: 0;
    white-space: nowrap;
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .overlay-text-header p {
        white-space: normal;
        text-align: left;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .overlay-container {
        height: 150%;
    }

    .overlay-text-header p {
        white-space: normal;
        font-size: 3.5rem;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .overlay-container {
        height: 160%;
    }

    .overlay-text-header p {
        white-space: normal;
        font-size: 2.5rem;
        text-align: left;
    }
}

@media (max-width: 320px) {
    .overlay-container {
        height: 120%;
    }

    .overlay-text-header p {
        font-size: 2.5rem;
    }
}


@media (max-width: 1024px) {
    .menu-inteiro {
        display: none;
    }
}

/*------------------------------------*
/* Seção destaques */
/*------------------------------------ */
.hero-image-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    color: white;
    padding-top: 120px;
    padding-bottom: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.hero-image-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
}

.hero-text,
.hero-buttons {
    position: relative;
    z-index: 1;
}


.hero-text {
    margin-bottom: 50px;
    z-index: 1;
}

.hero-text h1 {
    font-family: "ZeekrHeadline", sans-serif;
    font-weight: 500;
    font-size: 3rem;
}

.hero-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1;
    border-radius: 2px;
}

.hero-buttons .btn {
    border-radius: 3px;
}

.hero-buttons a {
    font-size: 1rem;
    padding: 10px 30px;
}

.btn-transparent {
    background-color: transparent;
    border: 1.5px solid white;
    color: white;
    transition: background-color 0.3s ease;
}

.btn-transparent:hover {
    border: 1.5px solid white;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}


@media (min-width: 600px) and (max-width: 1024px) {
    .hero-image-section {
        padding-top: 100px;
        align-items: flex-end;
        text-align: left;
    }

    .hero-text {
        margin-bottom: 50px;
    }

    .hero-text h1 {
        font-size: 2rem;
    }

    .hero-text p {
        font-size: 1.1rem;
    }

    .hero-buttons {
        flex-direction: row;
        gap: 15px;
    }
}

@media (max-width: 599px) {
    .hero-image-section {
        padding-top: 80px;
        align-items: flex-end;
        text-align: left;
    }

    .hero-text {
        margin-bottom: 30px;
    }

    .hero-text h1 {
        font-size: 2rem;
    }

    .hero-text p {
        font-size: 1.1rem;
    }

    .hero-buttons {
        position: absolute;
        display: flex;
        gap: 30px;
        bottom: 40px;
        width: 80%;
        left: 0;
        right: 0;
        z-index: 1;
        margin: auto;
        flex-direction: column;
    }
}

/*------------------------------------*
/* Seção texto apresentação Zeekr */
/*------------------------------------ */
.hero-section {
    background-color: #121215;
    color: white;
    min-height: 100vh;
}

.hero-section .container {
    max-width: 800px;
}

.title-text {
    font-size: 2.7rem;
}

.subtitle-text {
    font-size: 2.7rem;
    color: #ED8733;
}

@media (min-width: 600px) and (max-width: 1024px) {
    .hero-section {
        min-height: 50vh;
    }

    .hero-section .container {
        max-width: 720px;
    }

    .title-text {
        font-size: 2.2rem;
    }

    .subtitle-text {
        font-size: 2.2rem;
    }
}

@media (max-width: 599px) {
    .hero-section {
        min-height: 70vh;
    }

    .title-text {
        font-size: 1.9rem;
    }

    .subtitle-text {
        font-size: 1.9rem;
    }
}

@media (max-width: 320px) {
    .hero-section {
        min-height: 100vh;
    }
}