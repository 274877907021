@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//importar fonte que está em ../../../assets/fonts
@font-face {
    font-family: 'ZeekrHeadline';
    src: url('../../assets/fonts/ZeekrHeadline-Light.otf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ZeekrHeadline';
    src: url('../../assets/fonts/ZeekrHeadline-Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ZeekrText';
    src: url('../../assets/fonts/ZeekrText-Regular.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ZeekrText';
    src: url('../../assets/fonts/ZeekrText-Bold.otf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


// Cores
$branco: #fff;
$preto: #000;
$theme-color: #E22027;
$cta-color: #E22027;
$roxo: #272446;
$roxo-claro: #3d3871;

$gray: #E6E6E6;
$dark-gray: #58595b;

$cta-text-color: white;

$text-alt-color: #E74011;

// Fonts
$lato: 'fira sans', 'Lato', sans-serif;


// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}
